<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("NOTIFICATION") }}</h1>
      <div class="total">
        {{ getNotifs.length }} {{ $t("NOTIFICATION") }}
      </div>
    </div>

    <div class="content">
      <div class="head">
        <h1>{{ $t("NOTIFICATION") }}</h1>
        <font-awesome-icon
          :title="$t('DELETE')"
          icon="trash"
          class="icons"
          @click="delete_notifs"
        />
      </div>
      <div class="filtre">
        <div class="d-flex">
          <div
            class="tags mr-2"
            :class="{ select: filtreOn }"
            @click="getNotif"
          >
            {{ $t("ALL") }}
          </div>
          <div
            class="tags"
            :class="{ select: !filtreOn }"
            @click="getNotif(true)"
          >
            {{ $t("UNREAD") }}
          </div>
        </div>
      </div>
      <div class="block" v-if="getNotifs.length>0">
        <div
          class="notif"
          :class="{ notseen: !notif.seen }"
          v-for="(notif, i) in getNotifs"
          :key="i"
          @click="chooseActivity(notif)"
        >
          <div class="icone">
            <svg
              v-if="notif.message == 'Rendez vous'"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 21.688"
            >
              <g
                id="Icon_feather-calendar"
                data-name="Icon feather-calendar"
                transform="translate(0.75 0.75)"
              >
                <path
                  id="Tracé_78085"
                  data-name="Tracé 78085"
                  d="M6.519,6H20.65a2.019,2.019,0,0,1,2.019,2.019V22.15a2.019,2.019,0,0,1-2.019,2.019H6.519A2.019,2.019,0,0,1,4.5,22.15V8.019A2.019,2.019,0,0,1,6.519,6Z"
                  transform="translate(-4.5 -3.981)"
                  fill="none"
                  stroke="#28367a"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Tracé_78086"
                  data-name="Tracé 78086"
                  d="M24,3V7.037"
                  transform="translate(-10.878 -3)"
                  fill="none"
                  stroke="#28367a"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Tracé_78087"
                  data-name="Tracé 78087"
                  d="M12,3V7.037"
                  transform="translate(-6.953 -3)"
                  fill="none"
                  stroke="#28367a"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Tracé_78088"
                  data-name="Tracé 78088"
                  d="M4.5,15H22.669"
                  transform="translate(-4.5 -6.925)"
                  fill="none"
                  stroke="#28367a"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </g>
            </svg>
            <svg
              v-else-if="notif.message == 'Appel'"
              xmlns="http://www.w3.org/2000/svg"
              width="18.002"
              height="18.001"
              viewBox="0 0 18.002 18.001"
            >
              <path
                id="ios-call"
                d="M21.055,17.857a14.34,14.34,0,0,0-2.993-2c-.9-.431-1.226-.422-1.86.035-.529.382-.87.737-1.478.6a8.821,8.821,0,0,1-2.971-2.2,8.749,8.749,0,0,1-2.2-2.971c-.129-.613.227-.95.6-1.478.457-.635.47-.964.035-1.86a14.049,14.049,0,0,0-2-2.993c-.653-.653-.8-.511-1.159-.382a6.547,6.547,0,0,0-1.061.564A3.2,3.2,0,0,0,4.7,6.52c-.253.546-.546,1.563.946,4.218a23.533,23.533,0,0,0,4.138,5.519l0,0,0,0a23.624,23.624,0,0,0,5.519,4.138c2.655,1.492,3.672,1.2,4.218.946a3.145,3.145,0,0,0,1.345-1.274,6.637,6.637,0,0,0,.564-1.061c.129-.36.275-.506-.382-1.159Z"
                transform="translate(-4.015 -4.032)"
                fill="none"
                stroke="#28367a"
                stroke-width="1"
              />
            </svg>
            <svg
              v-else-if="notif.message == 'Tache'"
              xmlns="http://www.w3.org/2000/svg"
              width="17.058"
              height="17.058"
              viewBox="0 0 17.058 17.058"
            >
              <g id="request-quote" transform="translate(-4.5 -2.25)">
                <path
                  id="Tracé_2346"
                  data-name="Tracé 2346"
                  d="M15.466,14.434V18.09H5.718V3.468h6.092V2.25H5.718A1.218,1.218,0,0,0,4.5,3.468V18.09a1.218,1.218,0,0,0,1.218,1.218h9.747a1.218,1.218,0,0,0,1.218-1.218V14.434Z"
                />
                <path
                  id="Tracé_2347"
                  data-name="Tracé 2347"
                  d="M23.155,4.542l-2.01-2.01a.975.975,0,0,0-1.365,0l-8.529,8.529v3.375H14.62l8.529-8.529a.975.975,0,0,0,0-1.365Zm-9.041,8.675H12.469V11.572L18.22,5.815l1.651,1.651Zm6.616-6.61L19.079,4.956l1.383-1.383,1.651,1.651Z"
                  transform="translate(-3.095 -0.002)"
                />
              </g>
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="16.194"
              height="16.889"
              viewBox="0 0 16.194 16.889"
            >
              <path
                id="notification"
                d="M19.379,13.144,17.7,11.463V8.6a6.271,6.271,0,0,0-5.6-6.228,2.626,2.626,0,0,0-1.246,0A6.321,6.321,0,0,0,5.242,8.6v2.865L3.561,13.144a.567.567,0,0,0-.187.436v1.868A.585.585,0,0,0,4,16.071H8.356a3.114,3.114,0,1,0,6.228,0h4.359a.585.585,0,0,0,.623-.623V13.58A.567.567,0,0,0,19.379,13.144ZM11.47,17.939A1.868,1.868,0,0,1,9.6,16.071h3.737a1.868,1.868,0,0,1-1.868,1.868Zm6.85-3.114H4.619v-1L6.3,12.148a.567.567,0,0,0,.187-.436V8.6a4.982,4.982,0,0,1,9.964,0v3.114a.567.567,0,0,0,.187.436l1.682,1.682Z"
                transform="translate(-3.373 -2.296)"
              />
            </svg>
          </div>
          <div class="infos">
            <h3>{{ notif.message }}</h3>
            <h4>{{ moment(notif.date).format("dddd Do MMMM, hh:mm") }}</h4>
            <p>{{ moment(notif.created_at).startOf("day").fromNow() }}</p>
          </div>
          <div class="seen">
            <div class="round" v-if="!notif.seen"></div>
          </div>
        </div>
      </div>
      <div class="blockvide" v-else>
        <div class="bell">
          <svg xmlns="http://www.w3.org/2000/svg">
            <rect
              width="18.98"
              height="18.98"
              x="34.96"
              y="82"
              fill="#1876f2"
              rx="9.49"
              transform="rotate(-15 44.445 91.471)"
            />
            <circle cx="43.01" cy="26.27" r="6.85" fill="#7a7d81" />
            <path
              fill="#bcc0c4"
              d="M75.28 43.44a26.72 26.72 0 10-51.62 13.83L30 81l51.62-13.87z"
            />
            <path
              fill="#bcc0c4"
              d="M90.78 75.64L26.33 92.9l3.22-13.63 51.62-13.83 9.61 10.2z"
            />
            <rect
              width="66.91"
              height="8.88"
              x="25.35"
              y="80.75"
              fill="#bcc0c4"
              rx="4.44"
              transform="rotate(-15 58.793 85.207)"
            />
          </svg>
          <p>{{ $t("NO_NOTIF") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      filtreOn: true,
    };
  },
  methods: {
    ...mapActions(["all_notifs", "edit_notifs", "delete_notifs"]),

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },

    chooseActivity(activity) {
      this.edit_notifs(activity);
      if (activity.id_ref) this.$router.push("/client?id=" + activity.id_ref);
    },
    
    getNotif(bool = false) {
      if (bool == true) {
        this.filtreOn = false
        this.all_notifs({ seen: 0 });
      } else {
        this.filtreOn = true
        this.all_notifs();
      }
    },
  },

  mounted() {
    this.all_notifs()
  },

  computed: {
    ...mapGetters([
      "getNotifs",
      "getNotifLoading",
      "getNotifRows",
      "getTabs",
      "getSelectedLang",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  h1 {
    @media only screen and (max-width: 900px) {
      width: 60%;
    }
    flex-grow: 0;
    width: 80%;
  }

  .total{
    padding: 8px;
  }
}

.content {
  @media only screen and (max-width: 900px) {
    width: 92%;
  }
  margin: auto;
  width: 50%;
  box-shadow: 0 2px 6px #00000019;
  max-height: 81vh;
  min-height: 0;

  .head {
    @media only screen and (max-width: 900px) {
      padding: 4px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;

    h1 {
      color: #000;
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 0;
    }

    .icons {
      color: #000;
      width: 20px;
      height: 20px;
      cursor: pointer;

      :hover {
        color: red;
      }
    }

    .del_all {
      cursor: pointer;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 800;
      padding-bottom: 6px;
      border-radius: 50%;
      letter-spacing: 1.5px;

      &:hover {
        background-color: rgba(#fff, 0.1);
      }
    }
  }

  .filtre {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tags {
      padding: 6px 16px;
      border-radius: 18px;
      color: #000;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        background-color: rgba(#000, 0.1);
      }
    }

    .select {
      background-color: #34c38f;
      color: #fff;
      &:hover {
        background-color: rgba(#34c38f, 0.7);
      }
    }
  }

  .block {
    margin-top: 4px;
    max-height: calc(81vh - 112px);
    overflow: auto;

    .notif:last-child {
      border-bottom: 1px solid #d5d5d5;
    }
  }

  .blockvide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .bell {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        width: 112px;
        height: 112px;
      }

      p {
        @media only screen and (max-width: 900px) {
          font-size: 16px;
          margin: 0;
        }
        margin: 6px 0 0;
        font-size: 20px;
        color: #595959;
      }
    }
  }

  .notseen {
    background-color: #f8f8f8 !important;
  }

  .notif {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #d5d5d5;
    cursor: pointer;

    &:hover {
      background-color: #fcfcfc;
    }

    .icone {
      display: flex;
      justify-content: flex-start;
      width: 10%;
    }
    .infos {
      width: 80%;

      h3,
      h4,
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h3 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
        color: #595959;
      }

      h4 {
        font-size: 13px;
        color: #28367a;
        margin-bottom: 4px;
      }

      p {
        font-size: 12px;
        margin: 0;
        color: #cccccc;
      }
    }
    .seen {
      width: 10%;
      display: flex;
      justify-content: flex-end;

      .round {
        height: 12px;
        width: 12px;
        background-color: #28367a;
        border-radius: 50%;
      }
    }
  }
}
</style>
